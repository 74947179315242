import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const EventList = ({ leaderId }) => {
  const [events, setEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState(2); // Number of events to show initially
  const [sortedEvents, setSortedEvents] = useState([]);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/event/leader/${leaderId}`); // Replace with your API endpoint
        const sorted = response.data.events.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime)); // Sort by date
        setEvents(sorted);
        setSortedEvents(sorted.slice(0, visibleEvents)); // Initially show 2 events
        setHasError(false); // Clear any error state if successful
      } catch (error) {
        setHasError(true); // Set error state on failure
      }
    };
    fetchEvents();
  }, [visibleEvents, leaderId]);

  const showMoreEvents = () => {
    setSortedEvents(events.slice(0, visibleEvents + 2)); // Show more events on click
    setVisibleEvents(visibleEvents + 2);
  };

  const goToRSVPPage = (eventId) => {
    navigate(`/rsvp/${eventId}`); // Redirect to RSVP page with eventId
  };

  return (
    <div className="container mt-5">
      <h2 className='about-heading text-center'>Upcoming Events</h2>
      {sortedEvents.map((event) => (
        <div className="card shadow-lg mb-4" key={event._id}>
          <div className="card-body row  w-100">
            <div className='col-md-6'>
              <h5 className="card-title">{event.title}</h5>
              <p className="card-text">{event.shortDescription}</p>
            </div>
            <div className='col-md-6 event-txt-align'>
              <p><strong>Date:</strong> {format(new Date(event.dateTime), 'PPPpp')}</p>
              <p><strong>Location:</strong> {event.location ?<a href={event.location} target='_blank'>Google Map</a> : 'Virtual'}</p>
            </div>
            <button className="btn btn-custom-primary col-md-3 mx-auto" onClick={() => goToRSVPPage(event._id)}>Click Here for RSVP</button>
          </div>
        </div>
      ))}

      {visibleEvents < events.length && (
        <div className="text-center">
          <button className="btn btn-secondary" onClick={showMoreEvents}>View More</button>
        </div>
      )}
    </div>
  );
};

export default EventList;
