import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./AdminDashboard.css";


function AdminDashboard() {
  const [leaders, setLeaders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URLVAR}/api/leaders/admin`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLeaders(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLeaders();
  }, []);

  const deleteLeader = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URLVAR}/api/leaders/${id}`,
        { headers: { 'x-auth-token': localStorage.getItem("Token") }}
      );
      setLeaders((prevLeaders) =>
        prevLeaders.filter((leader) => leader._id !== id)
      );
      alert("Leader deleted successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to delete leader");
    }
  };

  const toggleShow = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URLVAR}/api/leaders/${id}/toggle-show`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem("Token") }}
      );
      const updatedLeader = response.data;
      setLeaders((prevLeaders) =>
        prevLeaders.map((leader) =>
          leader._id === id ? { ...leader, show: updatedLeader.show } : leader
        )
      );
      alert("Leader show value toggled successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to toggle leader show value");
    }
  };

  if (!localStorage.getItem("Token")) {
    return <></>;
  }

  return (
    <div>
      <Link to="/addLeader" className="btn btn-custom-primary m-5">
        Add Leader
      </Link>
      <Link to="/organizationedit" className="btn btn-custom-primary m-5">
       Organization Endorsement
      </Link>
      <Link to="/indivisualedit" className="btn btn-custom-primary m-5">
       Individual Endorsement
      </Link>
      <Link to="/eventform" className="btn btn-custom-primary m-5">
       Event Management
      </Link>

      <h1 className="text-center">Leader List</h1>
      <table className="table table-striped table-responsive container border rounded">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Show</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {leaders.map((leader, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{leader.name}</td>
              <td>{leader.email}</td>
              <td>{leader.mobile}</td>
              <td>
                <button
                  className={`btn ${leader.show ? "btn-success" : "btn-danger"}`}
                  onClick={() => toggleShow(leader._id)}
                >
                  {leader.show ? "Shown" : "Hidden"}
                </button>
              </td>
              <td>
                <Link to={"/update/" + leader.title.replaceAll(" ", "-")}>Edit</Link>
                <a className="ms-2" onClick={() => deleteLeader(leader._id)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
}

export default AdminDashboard;