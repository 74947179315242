import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EventRSVPList from './EventRSVPList';
import { Link } from 'react-router-dom';

const EventManager = () => {
  const [events, setEvents] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [selectedLeaderId, setSelectedLeaderId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [shouldFetch, setShouldFetch] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    shortDescription: '',
    dateTime: '',
    location: '',
    virtualLink: '',
    banner: null, // Add field to handle file input
    eventId: '',
  });

  // Fetch all events and leaders from the backend
  useEffect(() => {
    const fetchLeadersAndEvents = async () => {
      try {
        const leadersResponse = await axios.get(`${process.env.REACT_APP_URLVAR}/api/leaders/admin`);
        setLeaders(leadersResponse.data);
        const eventsResponse = await axios.get(`${process.env.REACT_APP_URLVAR}/api/event`, { headers: { 'x-auth-token': localStorage.getItem("Token") }});

        // Map over events and add leader name from leadersResponse based on leaderId
        const enrichedEvents = eventsResponse.data.events.map(event => {
          const leader = leadersResponse.data.find(leader => leader._id === event.leaderId);
          return { ...event, leaderName: leader ? leader.name : 'Unknown Leader' };
        });

        setEvents(enrichedEvents);
      } catch (error) {
        console.error('Error fetching events or leaders:', error);
      }
    };
    fetchLeadersAndEvents();
  }, [shouldFetch]);

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, banner: e.target.files[0] });
  };

  // Handle leader selection change
  const handleLeaderChange = (e) => {
    setSelectedLeaderId(e.target.value);
  };

  const formatDateTimeForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { title, shortDescription, dateTime, location, virtualLink, eventId, banner } = formData;

    const eventPayload = new FormData();
    eventPayload.append('title', title);
    eventPayload.append('shortDescription', shortDescription);
    eventPayload.append('dateTime', dateTime);
    eventPayload.append('location', location);
    eventPayload.append('virtualLink', virtualLink);
    eventPayload.append('leaderId', selectedLeaderId);
    if (banner) eventPayload.append('banner', banner);

    try {
      if (eventId) {
        // Update existing event
        await axios.put(`${process.env.REACT_APP_URLVAR}/api/event/${eventId}`, eventPayload, {
          headers: { 'x-auth-token': localStorage.getItem("Token"), 'Content-Type': 'multipart/form-data' }
        });
      } else {
        // Create new event
        await axios.post(`${process.env.REACT_APP_URLVAR}/api/event`, eventPayload, {
          headers: { 'x-auth-token': localStorage.getItem("Token"), 'Content-Type': 'multipart/form-data' }
        });
      }

      setFormData({ title: '', shortDescription: '', dateTime: '', location: '', virtualLink: '', banner: null, eventId: '' });
      setShouldFetch(prev => !prev);
    } catch (error) {
      console.error('Error adding or updating event:', error);
    }
  };

  const handleDelete = async (eventId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this event?");
    if (confirmDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_URLVAR}/api/event/${eventId}`, {
          headers: { 'x-auth-token': localStorage.getItem("Token") }
        });
        setShouldFetch(prev => !prev);
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredEvents = events.filter(event =>
    event.leaderName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = (event) => {
    setFormData({
      title: event.title,
      shortDescription: event.shortDescription,
      dateTime: formatDateTimeForInput(event.dateTime),
      location: event.location,
      virtualLink: event.virtualLink,
      banner: null, // Banner should not be preloaded into the form
      eventId: event._id,
    });
    setSelectedLeaderId(event.leaderId);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Event Manager</h2>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Event Title</label>
          <input type="text" className="form-control" id="title" name="title" value={formData.title} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="shortDescription" className="form-label">Short Description</label>
          <input type="text" className="form-control" id="shortDescription" name="shortDescription" value={formData.shortDescription} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="dateTime" className="form-label">Date & Time</label>
          <input type="datetime-local" className="form-control" id="dateTime" name="dateTime" value={formData.dateTime} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="location" className="form-label">Location</label>
          <input type="text" className="form-control" id="location" name="location" value={formData.location} onChange={handleInputChange} placeholder="Physical location or leave blank for virtual" />
        </div>
        <div className="mb-3">
          <label htmlFor="virtualLink" className="form-label">Virtual Link</label>
          <input type="url" className="form-control" id="virtualLink" name="virtualLink" value={formData.virtualLink} onChange={handleInputChange} placeholder="Virtual link (optional)" />
        </div>
        <div className="mb-3">
          <label htmlFor="banner" className="form-label">Banner Image</label>
          <input type="file" className="form-control" id="banner" name="banner" accept="image/*" onChange={handleFileChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="leader" className="form-label">Select Leader</label>
          <select className="form-select" id="leader" value={selectedLeaderId} onChange={handleLeaderChange} required>
            <option value="">Select a leader</option>
            {leaders.map((leader) => (
              <option key={leader._id} value={leader._id}>
                {leader.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">{formData.eventId ? 'Update Event' : 'Add Event'}</button>
      </form>

      <div className="mt-4">
        <input type="text" className="form-control" placeholder="Search events by leader name" value={searchTerm} onChange={handleSearchChange} />
      </div>

      <table className="table mt-4">
        <thead>
          <tr>
            <th>Title</th>
            <th>Short Description</th>
            <th>Date & Time</th>
            <th>Location</th>
            <th>Leader</th>
            <th>Rsvp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents.map(event => (
            <tr key={event._id}>
              <td>{event.title}</td>
              <td>{event.shortDescription}</td>
              <td>{new Date(event.dateTime).toLocaleString()}</td>
              <td>{event.location ?<a href={event.location} target='_blank'>Map link</a> : 'Virtual'}</td>
              <td>{event.leaderName}</td>
              <td>
              <Link to={`/event/${event._id}/rsvps`} className="btn btn-secondary text-white">RSVP</Link>
              </td>
              <td>
                <button className="btn btn-secondary" onClick={() => handleEdit(event)}><i className="bi bi-pencil-fill"></i></button>
                <button className="btn btn-danger ms-2" onClick={() => handleDelete(event._id)}><i className="bi bi-trash-fill"></i></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default EventManager;
