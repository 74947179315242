import React, { useState, useEffect } from "react";
import axios from "axios";

const IndividualList = () => {
  const [endorsements, setEndorsements] = useState([]);
  const [editingEndorsement, setEditingEndorsement] = useState(null);
  const [formData, setFormData] = useState({
    endorserType: "",
    organization: "",
    individual: {
      name: "",
      email: "",
      mobile: "",
      title: "",
      linkedin: "",
      message: "",
    },
    comments: "",
    leaderId: "",
  });

  useEffect(() => {
    const fetchEndorsements = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/general/individual`
        );
        setEndorsements(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching endorsements", error);
      }
    };

    fetchEndorsements();
  }, []);

  const handleEditClick = (endorsement) => {
    setEditingEndorsement(endorsement._id);
    setFormData(endorsement);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_URLVAR}/api/general/individual/${id}`);
      setEndorsements(
        endorsements.filter((endorsement) => endorsement._id !== id)
      );
    } catch (error) {
      console.error("Error deleting endorsement", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("individual.")) {
      const field = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        individual: {
          ...prevData.individual,
          [field]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     
        await axios.put( `${process.env.REACT_APP_URLVAR}/api/general/individual/${editingEndorsement}`, formData);
    
      setEditingEndorsement(null);
      setFormData({
        endorserType: "",
        organization: "",
        individual: {
          name: "",
          email: "",
          mobile: "",
          title: "",
          linkedin: "",
          message: "",
        },
        comments: "",
        leaderId: "",
      });
      const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/general/individual`);
      setEndorsements(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting endorsement", error);
    }
  };

  return (
    <div className="mx-5">
      <h1>Endorsements</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Leader Name</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Title</th>
            <th>LinkedIn</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(endorsements) && endorsements.map((endorsement) => (
            <tr key={endorsement._id}>
              <td>{endorsement.leaderName}</td>
              <td>{endorsement.individual.name}</td>
              <td>{endorsement.individual.email}</td>
              <td>{endorsement.individual.mobile}</td>
              <td>
                <textarea
                  rows={3}
                  disabled
                  value={endorsement.individual.title}
                />
              </td>
              <td>{endorsement.individual.linkedin}</td>

              <td>
                <textarea rows={3} disabled value={endorsement.comments} />
              </td>

              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => handleEditClick(endorsement)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteClick(endorsement._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingEndorsement && (
        <>
          <h2>{"Edit Endorsement"}</h2>
          <form onSubmit={handleSubmit}>
           
            
            <div className="form-group">
              <label>Individual Name</label>
              <input
                type="text"
                className="form-control"
                name="individual.name"
                value={formData.individual.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Individual Email</label>
              <input
                type="email"
                className="form-control"
                name="individual.email"
                value={formData.individual.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Individual Mobile</label>
              <input
                type="text"
                className="form-control"
                name="individual.mobile"
                value={formData.individual.mobile}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Individual Title</label>
              <input
                type="text"
                className="form-control"
                name="individual.title"
                value={formData.individual.title}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Individual LinkedIn</label>
              <input
                type="text"
                className="form-control"
                name="individual.linkedin"
                value={formData.individual.linkedin}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group">
              <label>Comments</label>
              <textarea
                className="form-control"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Leader ID</label>
              <input
                type="text"
                className="form-control"
                name="leaderId"
                value={formData.leaderId}
                onChange={handleChange}
                required
                disabled
              />
            </div>
            <button type="submit" className="btn btn-success">
             Update Endorsement
            </button>
           
              <button
                type="button"
                className="btn btn-secondary mt-2 ms-2"
                onClick={() => setEditingEndorsement(null)}
              >
                Cancel
              </button>
          
          </form>
        </>
      )}
    </div>
  );
};

export default IndividualList;
