import React from "react";

function AboutUs() {
  return (
    <div className="container border rounded my-5 p-5">
      <h2 className="text-center">About Us</h2>
      <h4>Problem:</h4>
      <p>
        The world is experiencing an unprecedented environmental crisis that is
        affecting every aspect of our lives. Despite the visible and
        scientifically proven impacts of climate change, finding leaders who
        genuinely care about preserving our planet’s ecosystem is still a
        challenge. Many leaders deny the science or lack a clear plan for
        climate action, even when elected.
      </p>

      <h4>Solution:</h4>
      <p>
        We aim to make it easier for people to find and support leaders who
        understand the science of climate change and have a clear vision for
        taking meaningful action. By connecting individuals with
        environmentally conscious leaders, we are working towards a future where
        sustainability is prioritized and real climate solutions are
        implemented.
      </p>

      <div className="w-100 d-flex justify-content-center">
        <iframe
          width="916"
          height="515"
          src="https://www.youtube.com/embed/A6PlwoBcV7g"
          title="Our Mission"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>

      <h4>Mission:</h4>
      <p>
        Our mission is to link people with leaders who are committed to
        environmental sustainability and taking strong, effective actions to
        safeguard our planet. We provide the tools and information needed to
        make informed decisions about which leaders to support, fostering a
        global community of individuals dedicated to tackling the critical
        environmental challenges we face today.
      </p>

      <h4>Our History:</h4>
      <p>
        Lead4Earth, originally known as Nisarg Foundation Inc., has a long
        history of supporting environmental initiatives and engaging in
        multilateral processes, particularly through the United Nations. We
        launched the "Road to Sustainability" initiative, a platform that gives
        environmental advocates the opportunity to present their ideas and
        strengthen climate adaptation efforts. Over the years, our work has
        positively impacted more than 50,000 individuals, establishing us as a
        strong voice in the fight against climate change.
      </p>
      <p>
        Learn more about our initiatives and their impacts at{" "}
        <a
          target="_blank"
          className="text-primary text-decoration-underline"
          href="https://lead4earth.com/"
        >
          lead4earth.com
        </a>
        .
      </p>
    </div>
  );
}

export default AboutUs;
