import React from 'react';


function PrivacyPolicy() {
    return (
        <div className='container border rounded my-5 pt-4'>
            <h3 className='text-center'>Privacy Policy</h3>
            <p>
                At Lead For Earth, we value the privacy of our users and are committed to protecting it. This Privacy Policy explains how we collect, use, and disclose information when you use our website.
            </p>
            <h5>Information We Collect</h5>
            <p>
                We collect the location of the user to customize the view as per their location. Apart from that, we do not collect any other personal information.
            </p>
            <h5>How We Use Your Information</h5>
            <p>
                We use the collected location information to show location-specific leaders to the users.
            </p>
            <h5>Information Sharing and Disclosure</h5>
            <p>
                We do not share or disclose user information with any third-party service providers or for any other purposes.
            </p>
            <h5>Security</h5>
            <p>
                We have implemented security measures to protect user information, including encryption and secure servers.
            </p>
            <h5>Changes to This Privacy Policy</h5>
            <p>
                We may update this privacy policy from time to time. Users will be notified of any changes through our website.
            </p>
            <h5>Contact Us</h5>
            <p>
                If you have any questions or concerns regarding our privacy policy, please contact us at [contact information].
            </p>
        </div>
    );
}

export default PrivacyPolicy;